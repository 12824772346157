%chipBase {
    @apply w-max flex flex-row justify-center items-center text-black font-italianplate font-bold not-italic border-0-1 border-black rounded-full whitespace-nowrap mx-1 cursor-pointer;
}

.chip {
    @extend %chipBase;
    &Active {
        @apply bg-mint border-white;
    }
}

.chip:focus {
    @apply outline-none border-1 border-black;
}

.chip:hover {
    @apply bg-mint-hover border-black;
}

.disabled {
    @extend %chipBase;
}

.disabled:focus {
    @apply outline-none;
}

.disabled,
.disabled:hover {
    @apply bg-gray80 text-gray30 border-white;
}

.large {
    @apply text-md h-16 px-8 py-5 leading-6;
}

.regular {
    @apply text-sm h-12 px-6 py-3 leading-5-5;
}

.small {
    @apply text-xs h-8 px-4 py-2 leading-5;
}

.labelPlusDropdown {
    @apply pr-3;
}

.labelPlusDropdownSmall {
    @apply pr-2;
}

.iconPlusLabel,
.inStoreLabel {
    @apply pl-3;
}

.inStoreLabel {
    @apply font-bold;
}

.iconPlusLabelSmall {
    @apply pl-2;
}
